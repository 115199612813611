import React from "react";
import { Link } from "gatsby";

const FeaturedPosts = ({ featuredPosts, allPosts }: {
    featuredPosts: string,
    allPosts: any,
}) => {

    const featuredWithSpaces = featuredPosts?.split(', ');
    const featuredWithoutSpaces = featuredPosts?.split(',');

    const featured = featuredWithSpaces.concat(featuredWithoutSpaces);

    return (
        <div className="bg-[#302536] rounded-2xl py-8 pb-4 px-7 md:px-10 mb-8 mt-16">
            <h2 className="text-xl text-white font-semibold mb-3">Related Posts</h2>
            <ul>
                {allPosts?.nodes?.map((loop: any, i: number) => (
                    featured && featured.includes(String(i)) &&
                    <li key={i}>
                        <Link
                            className="
                                text-sm py-4 block border-b border-[rgba(255,255,255,0.2)] pr-8
                                bg-no-repeat bg-right-center
                            "
                            to={'/blog/' + loop?.slug}
                            style={{
                                backgroundImage: "url('/assets/icons/arrow-up-right.svg')",
                                backgroundPosition: "center right",
                            }}
                        >
                            {loop?.frontmatter?.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FeaturedPosts;
