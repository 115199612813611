
export default function AuthorCard({ frontmatter, report }: {
    frontmatter: any,
    report?: boolean,
}) {

  return (
    report ? 
        <div className="max-w-[300px] text-xs mx-auto mt-6">
            <p className="opacity-50 mb-3">Report Author</p>
            {frontmatter?.authorName1 &&
                <div className="bg-[#443a4a] py-4 px-4 rounded-2xl flex items-center gap-2 justify-between">
                    <div className="flex items-center gap-2">
                        <img className="rounded-full w-8 h-8 object-fit" src={frontmatter?.authorImage1} alt={frontmatter.authorName1} />
                        <div>
                            <p><strong>{frontmatter.authorName1}</strong></p>
                            <p className="mt-[2px]">{frontmatter.authorTitle1}</p>
                        </div>
                    </div>
                    <a href={frontmatter.authorLinkedIn1} target="_blank" rel="noopener noreferrer">
                        <img src="/assets/icons/linkedin-icon.svg" alt="Follow me on LinkedIn" width="24" height="24" />
                    </a>
                </div>
            }
            {frontmatter?.authorName2 &&
                <div className="mt-2 bg-[#443a4a] py-4 px-4 rounded-2xl flex items-center gap-2 justify-between">
                    <div className="flex items-center gap-2">
                        <img className="rounded-full w-8 h-8 object-fit" src={frontmatter?.authorImage2} alt={frontmatter.authorName2} />
                        <div>
                            <p><strong>{frontmatter.authorName2}</strong></p>
                            <p className="mt-[2px]">{frontmatter.authorTitle2}</p>
                        </div>
                    </div>
                    <a href={frontmatter.authorLinkedIn2} target="_blank" rel="noopener noreferrer">
                        <img src="/assets/icons/linkedin-icon.svg" alt="Follow me on LinkedIn" width="24" height="24" />
                    </a>
                </div>
            }
        </div>
    :
        <div className="!mt-7 !mb-4 flex gap-y-2 gap-x-5 flex-col sm:flex-row items-center justify-center">
            {frontmatter?.authorName1 &&
                <div className="bg-[rgba(255,255,255,0.1)] max-w-max py-2 px-4 rounded-xl flex items-center gap-4 justify-between">
                    <img className="rounded-full w-8 h-8 object-fit" src={frontmatter?.authorImage1} alt={frontmatter.authorName1} />
                    <p>{frontmatter.authorName1}</p>
                    <a href={frontmatter.authorLinkedIn1} target="_blank" rel="noopener noreferrer">
                        <img src="/assets/icons/linkedin-icon.svg" alt="Follow me on LinkedIn" width="24" height="24" />
                    </a>
                </div>
            }
            {frontmatter?.authorName2 &&
                <div className="bg-[rgba(255,255,255,0.1)] max-w-max py-2 px-4 rounded-xl flex items-center gap-4 justify-between">
                    <img className="rounded-full w-8 h-8 object-fit" src={frontmatter?.authorImage2} alt={frontmatter.authorName2} />
                    <p>{frontmatter.authorName2}</p>
                    <a href={frontmatter.authorLinkedIn2} target="_blank" rel="noopener noreferrer">
                        <img src="/assets/icons/linkedin-icon.svg" alt="Follow me on LinkedIn" width="24" height="24" />
                    </a>
                </div>
            }
        </div>
  );
}
