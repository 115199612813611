import classNames from "classnames";
import React from "react";
import useBoolean from "../../hooks/useBoolean";

import { StarIcon, KeyGradientIcon } from "../../images/icons";
import { ReactComponent as RocketImage } from "../../images/rocket.svg";
import Button from "./Button";

interface IFeaturesBannerProps {
  onClick?: () => void;
  to?: string;
  title: string;
  description: string;
  buttonLabel: string;
  alternativeDesign?: boolean;
  noStar?: boolean,
}

const FeaturesBanner: React.FC<IFeaturesBannerProps> = ({
  onClick,
  to,
  title,
  description,
  buttonLabel,
  alternativeDesign,
  noStar,
}) => {
  const [isHover, setHover] = useBoolean();

  return (
    <div className={`
      w-full py-8 px-8 lg:px-10 flex flex-col-reverse lg:flex-row gap-6 justify-between
      rounded-2xl bg-gradient-to-r from-red-600 to-pink-600
      ${alternativeDesign ? 'text-center !mb-[-5.85em] relative z-[2]' : ''}
    `}>
      {!alternativeDesign && !noStar &&
        <div className="w-8 hidden lg:block">
          <i className="w-8 h-8 rounded-full border border-white flex items-center justify-center">
            <StarIcon className="text-white w-[11px] h-[11px]" />
          </i>
        </div>
      }
      <div className="w-full space-y-4">
        <h2 className="text-xl text-white font-semibold">{title}</h2>
        <p className="text-sm text-white">{description}</p>
        <div className="block lg:inline-block">
          <Button
            to={to}
            onClick={onClick}
            label={buttonLabel}
            EndIcon={RocketImage}
            RocketImage
            className="!w-full !lg:w-auto"
            iconProps={{
              className: `!w-7 !ml-[0.5em] !h-7 fill-[red] ${isHover ? "reset-icon" : ""}`,
              colorStart: "var(--color-red-600)",
              colorEnd: "var(--color-pink-600)",
            }}
            color="white"
            size="small"
            onHover={setHover.on}
            onLeave={setHover.off}
            labelClassName={classNames({
              "gradient-red-text": !isHover,
            })}
          />
        </div>
      </div>
      {!alternativeDesign &&
        <div className="max-w-[180px] w-full mx-auto">
          <RocketImage />
        </div>
      }
    </div>
  );
};

export default FeaturesBanner;
