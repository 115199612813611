import React, { useContext } from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import DefaultLayout from "../components/layouts/DefaultLayout";
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
import { AuthContext } from "../contexts/AuthContext";
import SEO from "../components/layouts/SEO";
import Hero from "../components/StaticPage/Hero";
import { IPost, ISiteMetaData } from "../types";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import FeaturesBanner from "../components/UI/FeaturesBanner";
import { MDXProvider } from "@mdx-js/react";
import CopyToClipboard from "../components/CopyToClipboard";
import FeaturedPosts from "../components/FeaturedPosts";
import AuthorCards from "../components/AuthorCard";


deckDeckGoHighlightElement();

interface IPostDetailsProps {
  data: {
    site: {
      siteMetadata: ISiteMetaData;
    };
    post: {
      frontmatter: IPost;
      excerpt: string;
      body: string;
      tableOfContents: string[];
    };
    allPosts: any;
  };
  pageContext: {
    slug: string;
  };
}

const PostDetails: React.FC<IPostDetailsProps> = ({ data, pageContext }) => {
  const { post, allPosts } = data;
  let { isAuthenticated } = useContext(AuthContext);

  if (!post) return null;

  const image = getImage(
    post.frontmatter.featured_image?.childImageSharp?.gatsbyImageData
  );

  const description = post.frontmatter?.excerpt
    ? post.frontmatter?.excerpt
    : post.excerpt;

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const releaseDate: Date = new Date(post.frontmatter?.date_published);
  const updatedDate: Date = new Date(post.frontmatter?.date_updated);

  const hasBeenUpdated = (post.frontmatter?.date_updated && (post.frontmatter?.date_updated !== post.frontmatter?.date_published)) ? true : false;
  const conditionalDate = hasBeenUpdated ? updatedDate : releaseDate;

  return (
    <DefaultLayout>
      <SEO
        title={`${post.frontmatter?.title} | Ensemble`}
        url={pageContext?.slug ? `${data?.site?.siteMetadata?.siteUrl}/blog/${pageContext.slug}` : ''}
        desc={description}
        ogImage={`${data?.site?.siteMetadata?.siteUrl}${post.frontmatter?.featured_image?.childImageSharp?.fixed?.src || '/assets/img/dbt.png'}`}
        article
      />
      <Hero
        title={post.frontmatter?.title}
        backgroundColor={["#40b7de", "#1b78e0"]}
        className="lg:!pb-[116px]"
      >
        {(post.frontmatter?.authorName1 || post.frontmatter?.authorName2) &&
          <AuthorCards frontmatter={post.frontmatter} />
        }
        {post.frontmatter?.date_published &&
          <time dateTime={post.frontmatter?.date_published} className="mx-auto max-w-max block !mt-8">
            {hasBeenUpdated && false ? 'Updated' : 'Published'} on {conditionalDate.getDate() + ' ' + months[conditionalDate.getMonth()] + ' ' + conditionalDate.getFullYear()}
          </time>
        }
      </Hero>
      <div className="max-w-[1188px] px-6 mx-auto w-full relative lg:top-[-62px] pt-10 lg:pt-0 pb-6 lg:pb-0">
        {post.frontmatter.featured_image ? (
          <GatsbyImage
            image={image as any}
            alt={post.frontmatter.title}
            sizes="(min-width: 1140px) 1140px, 100vw"
            objectFit="contain"
            className="h-auto w-full rounded-2xl"
            loading="eager"
          />
        ) : (
          <StaticImage
            src="../images/placeholder.png"
            alt="palceholder"
            sizes="(min-width: 1140px) 1140px, 100vw"
            objectFit="contain"
            imgClassName="rounded-lg"
            className="h-auto w-full rounded-2xl"
            placeholder="none"
            loading="eager"
          />
        )}
      </div>
      <div className="max-w-[870px] mx-auto px-6 lg:mt-[-28px]">
        <article className="mb-8 prose prose-lg max-w-none article-smaller-paragraphs">
          <style>
            {`
              .article-smaller-paragraphs p,
              .article-smaller-paragraphs li {
                font-size: 1rem;
              }
              .gatsby-resp-image-wrapper {
                margin: 1.5em 0;
              }
            `}
          </style>
          <MDXProvider
            components={{
              pre: CopyToClipboard,
            }}
          >
            <MDXRenderer localImages={post.frontmatter?.embeddedImagesLocal}>
              {post.body}
            </MDXRenderer>
          </MDXProvider>
        </article>
        <aside className="pb-14 lg:pb-[129px]">
          {post.frontmatter?.featured_posts && 
            <FeaturedPosts featuredPosts={post.frontmatter.featured_posts} allPosts={allPosts} />
          }
          {!isAuthenticated && (
            <FeaturesBanner
              noStar
              title="We Build AI Enabled Businesses"
              to="/"
              description="We help enterprise organisations build and run advanced data, analytics and AI enabled applications based on modern cloud-native technology."
              buttonLabel="Tell Me More"
            />
          )}
        </aside>
      </div>
    </DefaultLayout>
  );
};

export const query = graphql`
  query MDXQuery($slug: String) {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }

    post: mdx(slug: { eq: $slug }) {
      body
      tableOfContents
      excerpt
      frontmatter {
        date_published
        date_updated
        title
        background_color
        slug
        tag
        featured_posts
        excerpt
        featured_image {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData
          }
        }
        authorName1
        authorTitle1
        authorImage1
        authorLinkedIn1
        authorName2
        authorTitle2
        authorImage2
        authorLinkedIn2
      }
    }

    allPosts: allMdx(
      filter: { fields: { source: { eq: "blog" } } }
      sort: { fields: frontmatter___date_updated, order: ASC }
    ) {
      nodes {
        frontmatter {
          title
        }
        slug
      }
    }
  }
`;

export default PostDetails;
